<template lang="pug">
	div
		.widget
			.widget-header
				h1.title Countries
			.widget-body

				.form-inline
					.form-group.has-feedback
						input.form-control.input-search(type="text", placeholder="Search...", v-model="filters.keyword")
						span.form-control-feedback.la.la-search

				p.clearfix &nbsp;

				.ov-table-wrapper
					table.table.table-bordered.table-sm.table-hover.table-responsive-sm
						thead
							tr
								th
									.text &nbsp;
								th
									.text Code
								th
									.text Code3
								th
									.text Name
								th
									.text Timezone
						tbody
							tr(v-if="filteredRecords.length === 0")
								td(colspan="5") No matching records were found
							tr(v-for="r in filteredRecords", :key="r.code")
								td
									img.cn-flag(:src="'/assets/flags/'+r.code.toLowerCase()+'.png'")
								td {{ r.code }}
								td {{ r.code3 }}
								td {{ r.name }}
								td {{ r.timezone }}

</template>
<style>
.cn-flag {
  width: 30px;
}
</style>
<script>
export default {
  name: 'CountryList',
  computed: {
    filteredRecords: function () {
      // return this.records;
      // console.log(this.records);
      if (!this.filters.keyword) {
        return this.records;
      }
      let kw = this.filters.keyword.toLowerCase();
      return this.records.filter((r) => {
        return (
          r.code.toLowerCase().match(kw) ||
          r.code3.toLowerCase().match(kw) ||
          r.name.toLowerCase().match(kw)
        );
      });
    },
  },
  data() {
    return {
      busy: false,
      paginate: {
        numPages: 0,
        total: 0,
        page: 1,
        limit: 1000,
        onPageChange: () => {
          this.loadData();
        },
      },
      filters: {
        keyword: '',
      },
      records: [],
    };
  },
  methods: {
    async loadData() {
      this.busy = true;
      let params = {
        page: this.paginate.page,
        page_size: this.paginate.limit,
      };
      try {
        let resp = await this.$ovReq.get('country/getList', { params });
        this.records = resp.records;
        this.paginate.total = resp.total;
        this.paginate.numPages = Math.max(Math.ceil(this.paginate.total / this.paginate.limit), 1);
      } catch (e) {
        console.error(e);
      }
      this.busy = false;
    },
  },
  created() {
    this.loadData();
  },
};
</script>
